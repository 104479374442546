import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { ICancelData, Reservation } from '@facades/reservation'
import { useCancelReservation } from '@hooks/useCancelationReservation'
import { useGetQueryParam } from '@hooks/useGetQueryParam'
import { decodeBase64 } from '@utils/decodeBase64'
import { GameNames, getGameName, Locales } from '@utils/getGameName'
import { isBase64 } from '@utils/isBase64'
import { translationUtil } from '@utils/translationUtil'

import {
  ReservationCancelStyled,
  ReservationCancelItem,
  BtnContainer,
} from './reservationCancel.styled'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
export const ReservationCancel = ({
  reservation, // meta,
}: {
  reservation: Reservation
  meta?: { [key: string]: string }
}) => {
  const [showModal, setShowModal] = useState<ICancelData | boolean | number>(
    false
  )
  const { isLoading, mutate } = useCancelReservation(setShowModal)
  const translations = translationUtil()
  const cancelData = useGetQueryParam('?cancelReservation=')

  useEffect(() => {
    if (cancelData) {
      if (isBase64(cancelData)) {
        const parsed = JSON.parse(decodeBase64(cancelData))
        if (reservation?.[parsed?.date]?.[parsed?.time]) {
          setShowModal(parsed)
        }
      }
    }
  }, [cancelData, reservation])

  return (
    <Modal open={Boolean(showModal)}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {translations.cancelModalTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {translations.cancelModalSubTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <ReservationCancelStyled>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalName}:</span>
              <span className="value">{(showModal as ICancelData).name}</span>
            </ReservationCancelItem>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalDate}:</span>
              <span className="value">
                {moment((showModal as ICancelData).date).format('DD MMM, YYYY')}
              </span>
            </ReservationCancelItem>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalTime}:</span>
              <span className="value">
                {moment((showModal as ICancelData).time, 'HH:mm:ss').format(
                  'HH:mm'
                )}
              </span>
            </ReservationCancelItem>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalQuest}:</span>
              <span className="value">
                {getGameName(
                  (showModal as ICancelData).game as GameNames,
                  window?.wpmlLanguage as Locales
                )}
              </span>
            </ReservationCancelItem>
          </ReservationCancelStyled>
        </Typography>
        <BtnContainer>
          <Button onClick={() => setShowModal(false)} color="primary">
            {translations.cancelModalCancelBtn}
          </Button>
          <Button
            onClick={() => mutate({ data: cancelData as string })}
            color="warning"
          >
            {isLoading ? 'Loading...' : translations.cancelModalOKBtn}
          </Button>
        </BtnContainer>
      </Box>
    </Modal>
  )
}
